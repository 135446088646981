import '../sass/main.scss';

import $ from 'jquery';

(function() {
    $('.register-button').click(function (event) {
        event.preventDefault();
        window.location.href = '/register';
    });

    $('.login-button').click(function (event) {
        event.preventDefault();
        window.location.href = '/login';
    })
})()
